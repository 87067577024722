@charset "UTF-8";
/* Base font sizes */
/* Grid setup */
/* Spacing defaults */
/* Colors */
/* Spacers */
/* Bootstrap reboot overrides */
/* Custom variables */
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #306D50 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #204a36 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #306D50 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #204a36 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #FFCB56 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffbb23 !important; }

.bg-danger {
  background-color: #DC5F17 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ae4b12 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-black {
  background-color: #272E43 !important; }

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #141823 !important; }

.bg-white {
  background-color: #FFFFF5 !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #ffffc2 !important; }

.bg-beige {
  background-color: #F5F4EA !important; }

a.bg-beige:hover, a.bg-beige:focus,
button.bg-beige:hover,
button.bg-beige:focus {
  background-color: #e5e2c7 !important; }

.bg-yellow {
  background-color: #FFCB56 !important; }

a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
  background-color: #ffbb23 !important; }

.bg-orange {
  background-color: #FF972D !important; }

a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
  background-color: #f97e00 !important; }

.bg-light-orange {
  background-color: #FFB37C !important; }

a.bg-light-orange:hover, a.bg-light-orange:focus,
button.bg-light-orange:hover,
button.bg-light-orange:focus {
  background-color: #ff9549 !important; }

.bg-red {
  background-color: #DC5F17 !important; }

a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
  background-color: #ae4b12 !important; }

.bg-green {
  background-color: #306D50 !important; }

a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
  background-color: #204a36 !important; }

.bg-dark-blue {
  background-color: #1B3F47 !important; }

a.bg-dark-blue:hover, a.bg-dark-blue:focus,
button.bg-dark-blue:hover,
button.bg-dark-blue:focus {
  background-color: #0d1e22 !important; }

.bg-blue {
  background-color: #B2E0E8 !important; }

a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
  background-color: #8bd0dc !important; }

.bg-white {
  background-color: #FFFFF5 !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #306D50 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #306D50 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #FFCB56 !important; }

.border-danger {
  border-color: #DC5F17 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-black {
  border-color: #272E43 !important; }

.border-white {
  border-color: #FFFFF5 !important; }

.border-beige {
  border-color: #F5F4EA !important; }

.border-yellow {
  border-color: #FFCB56 !important; }

.border-orange {
  border-color: #FF972D !important; }

.border-light-orange {
  border-color: #FFB37C !important; }

.border-red {
  border-color: #DC5F17 !important; }

.border-green {
  border-color: #306D50 !important; }

.border-dark-blue {
  border-color: #1B3F47 !important; }

.border-blue {
  border-color: #B2E0E8 !important; }

.border-white {
  border-color: #FFFFF5 !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1100px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1100px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1100px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(39, 46, 67, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(39, 46, 67, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(39, 46, 67, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-2 {
  margin: 2rem !important; }

.mt-2,
.my-2 {
  margin-top: 2rem !important; }

.mr-2,
.mx-2 {
  margin-right: 2rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 2rem !important; }

.ml-2,
.mx-2 {
  margin-left: 2rem !important; }

.m-3 {
  margin: 3rem !important; }

.mt-3,
.my-3 {
  margin-top: 3rem !important; }

.mr-3,
.mx-3 {
  margin-right: 3rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 3rem !important; }

.ml-3,
.mx-3 {
  margin-left: 3rem !important; }

.m-4 {
  margin: 4rem !important; }

.mt-4,
.my-4 {
  margin-top: 4rem !important; }

.mr-4,
.mx-4 {
  margin-right: 4rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 4rem !important; }

.ml-4,
.mx-4 {
  margin-left: 4rem !important; }

.m-5 {
  margin: 5rem !important; }

.mt-5,
.my-5 {
  margin-top: 5rem !important; }

.mr-5,
.mx-5 {
  margin-right: 5rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 5rem !important; }

.ml-5,
.mx-5 {
  margin-left: 5rem !important; }

.m-6 {
  margin: 6rem !important; }

.mt-6,
.my-6 {
  margin-top: 6rem !important; }

.mr-6,
.mx-6 {
  margin-right: 6rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 6rem !important; }

.ml-6,
.mx-6 {
  margin-left: 6rem !important; }

.m-7 {
  margin: 7rem !important; }

.mt-7,
.my-7 {
  margin-top: 7rem !important; }

.mr-7,
.mx-7 {
  margin-right: 7rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 7rem !important; }

.ml-7,
.mx-7 {
  margin-left: 7rem !important; }

.m-8 {
  margin: 8rem !important; }

.mt-8,
.my-8 {
  margin-top: 8rem !important; }

.mr-8,
.mx-8 {
  margin-right: 8rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 8rem !important; }

.ml-8,
.mx-8 {
  margin-left: 8rem !important; }

.m-9 {
  margin: 9rem !important; }

.mt-9,
.my-9 {
  margin-top: 9rem !important; }

.mr-9,
.mx-9 {
  margin-right: 9rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 9rem !important; }

.ml-9,
.mx-9 {
  margin-left: 9rem !important; }

.m-10 {
  margin: 10rem !important; }

.mt-10,
.my-10 {
  margin-top: 10rem !important; }

.mr-10,
.mx-10 {
  margin-right: 10rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 10rem !important; }

.ml-10,
.mx-10 {
  margin-left: 10rem !important; }

.m-12 {
  margin: 12rem !important; }

.mt-12,
.my-12 {
  margin-top: 12rem !important; }

.mr-12,
.mx-12 {
  margin-right: 12rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 12rem !important; }

.ml-12,
.mx-12 {
  margin-left: 12rem !important; }

.m-15 {
  margin: 15rem !important; }

.mt-15,
.my-15 {
  margin-top: 15rem !important; }

.mr-15,
.mx-15 {
  margin-right: 15rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 15rem !important; }

.ml-15,
.mx-15 {
  margin-left: 15rem !important; }

.m-grid {
  margin: 1.875rem !important; }

.mt-grid,
.my-grid {
  margin-top: 1.875rem !important; }

.mr-grid,
.mx-grid {
  margin-right: 1.875rem !important; }

.mb-grid,
.my-grid {
  margin-bottom: 1.875rem !important; }

.ml-grid,
.mx-grid {
  margin-left: 1.875rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-2 {
  padding: 2rem !important; }

.pt-2,
.py-2 {
  padding-top: 2rem !important; }

.pr-2,
.px-2 {
  padding-right: 2rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 2rem !important; }

.pl-2,
.px-2 {
  padding-left: 2rem !important; }

.p-3 {
  padding: 3rem !important; }

.pt-3,
.py-3 {
  padding-top: 3rem !important; }

.pr-3,
.px-3 {
  padding-right: 3rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 3rem !important; }

.pl-3,
.px-3 {
  padding-left: 3rem !important; }

.p-4 {
  padding: 4rem !important; }

.pt-4,
.py-4 {
  padding-top: 4rem !important; }

.pr-4,
.px-4 {
  padding-right: 4rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 4rem !important; }

.pl-4,
.px-4 {
  padding-left: 4rem !important; }

.p-5 {
  padding: 5rem !important; }

.pt-5,
.py-5 {
  padding-top: 5rem !important; }

.pr-5,
.px-5 {
  padding-right: 5rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 5rem !important; }

.pl-5,
.px-5 {
  padding-left: 5rem !important; }

.p-6 {
  padding: 6rem !important; }

.pt-6,
.py-6 {
  padding-top: 6rem !important; }

.pr-6,
.px-6 {
  padding-right: 6rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 6rem !important; }

.pl-6,
.px-6 {
  padding-left: 6rem !important; }

.p-7 {
  padding: 7rem !important; }

.pt-7,
.py-7 {
  padding-top: 7rem !important; }

.pr-7,
.px-7 {
  padding-right: 7rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 7rem !important; }

.pl-7,
.px-7 {
  padding-left: 7rem !important; }

.p-8 {
  padding: 8rem !important; }

.pt-8,
.py-8 {
  padding-top: 8rem !important; }

.pr-8,
.px-8 {
  padding-right: 8rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 8rem !important; }

.pl-8,
.px-8 {
  padding-left: 8rem !important; }

.p-9 {
  padding: 9rem !important; }

.pt-9,
.py-9 {
  padding-top: 9rem !important; }

.pr-9,
.px-9 {
  padding-right: 9rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 9rem !important; }

.pl-9,
.px-9 {
  padding-left: 9rem !important; }

.p-10 {
  padding: 10rem !important; }

.pt-10,
.py-10 {
  padding-top: 10rem !important; }

.pr-10,
.px-10 {
  padding-right: 10rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 10rem !important; }

.pl-10,
.px-10 {
  padding-left: 10rem !important; }

.p-12 {
  padding: 12rem !important; }

.pt-12,
.py-12 {
  padding-top: 12rem !important; }

.pr-12,
.px-12 {
  padding-right: 12rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 12rem !important; }

.pl-12,
.px-12 {
  padding-left: 12rem !important; }

.p-15 {
  padding: 15rem !important; }

.pt-15,
.py-15 {
  padding-top: 15rem !important; }

.pr-15,
.px-15 {
  padding-right: 15rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 15rem !important; }

.pl-15,
.px-15 {
  padding-left: 15rem !important; }

.p-grid {
  padding: 1.875rem !important; }

.pt-grid,
.py-grid {
  padding-top: 1.875rem !important; }

.pr-grid,
.px-grid {
  padding-right: 1.875rem !important; }

.pb-grid,
.py-grid {
  padding-bottom: 1.875rem !important; }

.pl-grid,
.px-grid {
  padding-left: 1.875rem !important; }

.m-n1 {
  margin: -1rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -1rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -1rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -1rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -1rem !important; }

.m-n2 {
  margin: -2rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -2rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -2rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -2rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -2rem !important; }

.m-n3 {
  margin: -3rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -3rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -3rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -3rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -3rem !important; }

.m-n4 {
  margin: -4rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -4rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -4rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -4rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -4rem !important; }

.m-n5 {
  margin: -5rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -5rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -5rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -5rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -5rem !important; }

.m-n6 {
  margin: -6rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -6rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -6rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -6rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -6rem !important; }

.m-n7 {
  margin: -7rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -7rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -7rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -7rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -7rem !important; }

.m-n8 {
  margin: -8rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -8rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -8rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -8rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -8rem !important; }

.m-n9 {
  margin: -9rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -9rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -9rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -9rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -9rem !important; }

.m-n10 {
  margin: -10rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -10rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -10rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -10rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -10rem !important; }

.m-n12 {
  margin: -12rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -12rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -12rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -12rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -12rem !important; }

.m-n15 {
  margin: -15rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -15rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -15rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -15rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -15rem !important; }

.m-ngrid {
  margin: -1.875rem !important; }

.mt-ngrid,
.my-ngrid {
  margin-top: -1.875rem !important; }

.mr-ngrid,
.mx-ngrid {
  margin-right: -1.875rem !important; }

.mb-ngrid,
.my-ngrid {
  margin-bottom: -1.875rem !important; }

.ml-ngrid,
.mx-ngrid {
  margin-left: -1.875rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-2 {
    margin: 2rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2rem !important; }
  .m-sm-3 {
    margin: 3rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 3rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 3rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 3rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 3rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 4rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 4rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 4rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 4rem !important; }
  .m-sm-5 {
    margin: 5rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 5rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 5rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 5rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 5rem !important; }
  .m-sm-6 {
    margin: 6rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important; }
  .m-sm-7 {
    margin: 7rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 7rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 7rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 7rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 7rem !important; }
  .m-sm-8 {
    margin: 8rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 8rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 8rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 8rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 8rem !important; }
  .m-sm-9 {
    margin: 9rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 9rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 9rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 9rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 9rem !important; }
  .m-sm-10 {
    margin: 10rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 10rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 10rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 10rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 10rem !important; }
  .m-sm-12 {
    margin: 12rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 12rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 12rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 12rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 12rem !important; }
  .m-sm-15 {
    margin: 15rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15rem !important; }
  .m-sm-grid {
    margin: 1.875rem !important; }
  .mt-sm-grid,
  .my-sm-grid {
    margin-top: 1.875rem !important; }
  .mr-sm-grid,
  .mx-sm-grid {
    margin-right: 1.875rem !important; }
  .mb-sm-grid,
  .my-sm-grid {
    margin-bottom: 1.875rem !important; }
  .ml-sm-grid,
  .mx-sm-grid {
    margin-left: 1.875rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-2 {
    padding: 2rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2rem !important; }
  .p-sm-3 {
    padding: 3rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 3rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 3rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 3rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 3rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 4rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 4rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 4rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 4rem !important; }
  .p-sm-5 {
    padding: 5rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 5rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 5rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 5rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 5rem !important; }
  .p-sm-6 {
    padding: 6rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important; }
  .p-sm-7 {
    padding: 7rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 7rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 7rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 7rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 7rem !important; }
  .p-sm-8 {
    padding: 8rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 8rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 8rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 8rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 8rem !important; }
  .p-sm-9 {
    padding: 9rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 9rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 9rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 9rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 9rem !important; }
  .p-sm-10 {
    padding: 10rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 10rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 10rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 10rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 10rem !important; }
  .p-sm-12 {
    padding: 12rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 12rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 12rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 12rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 12rem !important; }
  .p-sm-15 {
    padding: 15rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15rem !important; }
  .p-sm-grid {
    padding: 1.875rem !important; }
  .pt-sm-grid,
  .py-sm-grid {
    padding-top: 1.875rem !important; }
  .pr-sm-grid,
  .px-sm-grid {
    padding-right: 1.875rem !important; }
  .pb-sm-grid,
  .py-sm-grid {
    padding-bottom: 1.875rem !important; }
  .pl-sm-grid,
  .px-sm-grid {
    padding-left: 1.875rem !important; }
  .m-sm-n1 {
    margin: -1rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1rem !important; }
  .m-sm-n2 {
    margin: -2rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2rem !important; }
  .m-sm-n3 {
    margin: -3rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -3rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -3rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -3rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -3rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -4rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -4rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -4rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -4rem !important; }
  .m-sm-n5 {
    margin: -5rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -5rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -5rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -5rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -5rem !important; }
  .m-sm-n6 {
    margin: -6rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -6rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -6rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -6rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -6rem !important; }
  .m-sm-n7 {
    margin: -7rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -7rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -7rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -7rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -7rem !important; }
  .m-sm-n8 {
    margin: -8rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -8rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -8rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -8rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -8rem !important; }
  .m-sm-n9 {
    margin: -9rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -9rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -9rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -9rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -9rem !important; }
  .m-sm-n10 {
    margin: -10rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -10rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -10rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -10rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -10rem !important; }
  .m-sm-n12 {
    margin: -12rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -12rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -12rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -12rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -12rem !important; }
  .m-sm-n15 {
    margin: -15rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15rem !important; }
  .m-sm-ngrid {
    margin: -1.875rem !important; }
  .mt-sm-ngrid,
  .my-sm-ngrid {
    margin-top: -1.875rem !important; }
  .mr-sm-ngrid,
  .mx-sm-ngrid {
    margin-right: -1.875rem !important; }
  .mb-sm-ngrid,
  .my-sm-ngrid {
    margin-bottom: -1.875rem !important; }
  .ml-sm-ngrid,
  .mx-sm-ngrid {
    margin-left: -1.875rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-2 {
    margin: 2rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 2rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 2rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 2rem !important; }
  .m-md-3 {
    margin: 3rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 3rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 3rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 3rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 3rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 4rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 4rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 4rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 4rem !important; }
  .m-md-5 {
    margin: 5rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 5rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 5rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 5rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 5rem !important; }
  .m-md-6 {
    margin: 6rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important; }
  .m-md-7 {
    margin: 7rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 7rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 7rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 7rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 7rem !important; }
  .m-md-8 {
    margin: 8rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 8rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 8rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 8rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 8rem !important; }
  .m-md-9 {
    margin: 9rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 9rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 9rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 9rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 9rem !important; }
  .m-md-10 {
    margin: 10rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 10rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 10rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 10rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 10rem !important; }
  .m-md-12 {
    margin: 12rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 12rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 12rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 12rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 12rem !important; }
  .m-md-15 {
    margin: 15rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15rem !important; }
  .m-md-grid {
    margin: 1.875rem !important; }
  .mt-md-grid,
  .my-md-grid {
    margin-top: 1.875rem !important; }
  .mr-md-grid,
  .mx-md-grid {
    margin-right: 1.875rem !important; }
  .mb-md-grid,
  .my-md-grid {
    margin-bottom: 1.875rem !important; }
  .ml-md-grid,
  .mx-md-grid {
    margin-left: 1.875rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-2 {
    padding: 2rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 2rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 2rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 2rem !important; }
  .p-md-3 {
    padding: 3rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 3rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 3rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 3rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 3rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 4rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 4rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 4rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 4rem !important; }
  .p-md-5 {
    padding: 5rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 5rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 5rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 5rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 5rem !important; }
  .p-md-6 {
    padding: 6rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important; }
  .p-md-7 {
    padding: 7rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 7rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 7rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 7rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 7rem !important; }
  .p-md-8 {
    padding: 8rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 8rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 8rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 8rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 8rem !important; }
  .p-md-9 {
    padding: 9rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 9rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 9rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 9rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 9rem !important; }
  .p-md-10 {
    padding: 10rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 10rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 10rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 10rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 10rem !important; }
  .p-md-12 {
    padding: 12rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 12rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 12rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 12rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 12rem !important; }
  .p-md-15 {
    padding: 15rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15rem !important; }
  .p-md-grid {
    padding: 1.875rem !important; }
  .pt-md-grid,
  .py-md-grid {
    padding-top: 1.875rem !important; }
  .pr-md-grid,
  .px-md-grid {
    padding-right: 1.875rem !important; }
  .pb-md-grid,
  .py-md-grid {
    padding-bottom: 1.875rem !important; }
  .pl-md-grid,
  .px-md-grid {
    padding-left: 1.875rem !important; }
  .m-md-n1 {
    margin: -1rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1rem !important; }
  .m-md-n2 {
    margin: -2rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2rem !important; }
  .m-md-n3 {
    margin: -3rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -3rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -3rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -3rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -3rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -4rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -4rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -4rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -4rem !important; }
  .m-md-n5 {
    margin: -5rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -5rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -5rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -5rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -5rem !important; }
  .m-md-n6 {
    margin: -6rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -6rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -6rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -6rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -6rem !important; }
  .m-md-n7 {
    margin: -7rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -7rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -7rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -7rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -7rem !important; }
  .m-md-n8 {
    margin: -8rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -8rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -8rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -8rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -8rem !important; }
  .m-md-n9 {
    margin: -9rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -9rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -9rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -9rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -9rem !important; }
  .m-md-n10 {
    margin: -10rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -10rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -10rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -10rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -10rem !important; }
  .m-md-n12 {
    margin: -12rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -12rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -12rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -12rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -12rem !important; }
  .m-md-n15 {
    margin: -15rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15rem !important; }
  .m-md-ngrid {
    margin: -1.875rem !important; }
  .mt-md-ngrid,
  .my-md-ngrid {
    margin-top: -1.875rem !important; }
  .mr-md-ngrid,
  .mx-md-ngrid {
    margin-right: -1.875rem !important; }
  .mb-md-ngrid,
  .my-md-ngrid {
    margin-bottom: -1.875rem !important; }
  .ml-md-ngrid,
  .mx-md-ngrid {
    margin-left: -1.875rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1100px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-2 {
    margin: 2rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2rem !important; }
  .m-lg-3 {
    margin: 3rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 3rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 3rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 3rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 3rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 4rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 4rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 4rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 4rem !important; }
  .m-lg-5 {
    margin: 5rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 5rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 5rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 5rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 5rem !important; }
  .m-lg-6 {
    margin: 6rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important; }
  .m-lg-7 {
    margin: 7rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 7rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 7rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 7rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 7rem !important; }
  .m-lg-8 {
    margin: 8rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 8rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 8rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 8rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 8rem !important; }
  .m-lg-9 {
    margin: 9rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 9rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 9rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 9rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 9rem !important; }
  .m-lg-10 {
    margin: 10rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 10rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 10rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 10rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 10rem !important; }
  .m-lg-12 {
    margin: 12rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 12rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 12rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 12rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 12rem !important; }
  .m-lg-15 {
    margin: 15rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15rem !important; }
  .m-lg-grid {
    margin: 1.875rem !important; }
  .mt-lg-grid,
  .my-lg-grid {
    margin-top: 1.875rem !important; }
  .mr-lg-grid,
  .mx-lg-grid {
    margin-right: 1.875rem !important; }
  .mb-lg-grid,
  .my-lg-grid {
    margin-bottom: 1.875rem !important; }
  .ml-lg-grid,
  .mx-lg-grid {
    margin-left: 1.875rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-2 {
    padding: 2rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2rem !important; }
  .p-lg-3 {
    padding: 3rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 3rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 3rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 3rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 3rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 4rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 4rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 4rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 4rem !important; }
  .p-lg-5 {
    padding: 5rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 5rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 5rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 5rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 5rem !important; }
  .p-lg-6 {
    padding: 6rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important; }
  .p-lg-7 {
    padding: 7rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 7rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 7rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 7rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 7rem !important; }
  .p-lg-8 {
    padding: 8rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 8rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 8rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 8rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 8rem !important; }
  .p-lg-9 {
    padding: 9rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 9rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 9rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 9rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 9rem !important; }
  .p-lg-10 {
    padding: 10rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 10rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 10rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 10rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 10rem !important; }
  .p-lg-12 {
    padding: 12rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 12rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 12rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 12rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 12rem !important; }
  .p-lg-15 {
    padding: 15rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15rem !important; }
  .p-lg-grid {
    padding: 1.875rem !important; }
  .pt-lg-grid,
  .py-lg-grid {
    padding-top: 1.875rem !important; }
  .pr-lg-grid,
  .px-lg-grid {
    padding-right: 1.875rem !important; }
  .pb-lg-grid,
  .py-lg-grid {
    padding-bottom: 1.875rem !important; }
  .pl-lg-grid,
  .px-lg-grid {
    padding-left: 1.875rem !important; }
  .m-lg-n1 {
    margin: -1rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1rem !important; }
  .m-lg-n2 {
    margin: -2rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2rem !important; }
  .m-lg-n3 {
    margin: -3rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -3rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -3rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -3rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -3rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -4rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -4rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -4rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -4rem !important; }
  .m-lg-n5 {
    margin: -5rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -5rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -5rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -5rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -5rem !important; }
  .m-lg-n6 {
    margin: -6rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -6rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -6rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -6rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -6rem !important; }
  .m-lg-n7 {
    margin: -7rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -7rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -7rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -7rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -7rem !important; }
  .m-lg-n8 {
    margin: -8rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -8rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -8rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -8rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -8rem !important; }
  .m-lg-n9 {
    margin: -9rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -9rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -9rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -9rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -9rem !important; }
  .m-lg-n10 {
    margin: -10rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -10rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -10rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -10rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -10rem !important; }
  .m-lg-n12 {
    margin: -12rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -12rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -12rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -12rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -12rem !important; }
  .m-lg-n15 {
    margin: -15rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15rem !important; }
  .m-lg-ngrid {
    margin: -1.875rem !important; }
  .mt-lg-ngrid,
  .my-lg-ngrid {
    margin-top: -1.875rem !important; }
  .mr-lg-ngrid,
  .mx-lg-ngrid {
    margin-right: -1.875rem !important; }
  .mb-lg-ngrid,
  .my-lg-ngrid {
    margin-bottom: -1.875rem !important; }
  .ml-lg-ngrid,
  .mx-lg-ngrid {
    margin-left: -1.875rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 1rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1rem !important; }
  .m-xl-2 {
    margin: 2rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2rem !important; }
  .m-xl-3 {
    margin: 3rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 3rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 3rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 3rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 3rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 4rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 4rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 4rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 4rem !important; }
  .m-xl-5 {
    margin: 5rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 5rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 5rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 5rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 5rem !important; }
  .m-xl-6 {
    margin: 6rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 6rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 6rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 6rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 6rem !important; }
  .m-xl-7 {
    margin: 7rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 7rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 7rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 7rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 7rem !important; }
  .m-xl-8 {
    margin: 8rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 8rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 8rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 8rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 8rem !important; }
  .m-xl-9 {
    margin: 9rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 9rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 9rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 9rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 9rem !important; }
  .m-xl-10 {
    margin: 10rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 10rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 10rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 10rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 10rem !important; }
  .m-xl-12 {
    margin: 12rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 12rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 12rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 12rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 12rem !important; }
  .m-xl-15 {
    margin: 15rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15rem !important; }
  .m-xl-grid {
    margin: 1.875rem !important; }
  .mt-xl-grid,
  .my-xl-grid {
    margin-top: 1.875rem !important; }
  .mr-xl-grid,
  .mx-xl-grid {
    margin-right: 1.875rem !important; }
  .mb-xl-grid,
  .my-xl-grid {
    margin-bottom: 1.875rem !important; }
  .ml-xl-grid,
  .mx-xl-grid {
    margin-left: 1.875rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 1rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1rem !important; }
  .p-xl-2 {
    padding: 2rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2rem !important; }
  .p-xl-3 {
    padding: 3rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 3rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 3rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 3rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 3rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 4rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 4rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 4rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 4rem !important; }
  .p-xl-5 {
    padding: 5rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 5rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 5rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 5rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 5rem !important; }
  .p-xl-6 {
    padding: 6rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 6rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 6rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 6rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 6rem !important; }
  .p-xl-7 {
    padding: 7rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 7rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 7rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 7rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 7rem !important; }
  .p-xl-8 {
    padding: 8rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 8rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 8rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 8rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 8rem !important; }
  .p-xl-9 {
    padding: 9rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 9rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 9rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 9rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 9rem !important; }
  .p-xl-10 {
    padding: 10rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 10rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 10rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 10rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 10rem !important; }
  .p-xl-12 {
    padding: 12rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 12rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 12rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 12rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 12rem !important; }
  .p-xl-15 {
    padding: 15rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15rem !important; }
  .p-xl-grid {
    padding: 1.875rem !important; }
  .pt-xl-grid,
  .py-xl-grid {
    padding-top: 1.875rem !important; }
  .pr-xl-grid,
  .px-xl-grid {
    padding-right: 1.875rem !important; }
  .pb-xl-grid,
  .py-xl-grid {
    padding-bottom: 1.875rem !important; }
  .pl-xl-grid,
  .px-xl-grid {
    padding-left: 1.875rem !important; }
  .m-xl-n1 {
    margin: -1rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1rem !important; }
  .m-xl-n2 {
    margin: -2rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2rem !important; }
  .m-xl-n3 {
    margin: -3rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -3rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -3rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -3rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -3rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -4rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -4rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -4rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -4rem !important; }
  .m-xl-n5 {
    margin: -5rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -5rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -5rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -5rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -5rem !important; }
  .m-xl-n6 {
    margin: -6rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -6rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -6rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -6rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -6rem !important; }
  .m-xl-n7 {
    margin: -7rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -7rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -7rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -7rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -7rem !important; }
  .m-xl-n8 {
    margin: -8rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -8rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -8rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -8rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -8rem !important; }
  .m-xl-n9 {
    margin: -9rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -9rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -9rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -9rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -9rem !important; }
  .m-xl-n10 {
    margin: -10rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -10rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -10rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -10rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -10rem !important; }
  .m-xl-n12 {
    margin: -12rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -12rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -12rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -12rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -12rem !important; }
  .m-xl-n15 {
    margin: -15rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -15rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -15rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -15rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -15rem !important; }
  .m-xl-ngrid {
    margin: -1.875rem !important; }
  .mt-xl-ngrid,
  .my-xl-ngrid {
    margin-top: -1.875rem !important; }
  .mr-xl-ngrid,
  .mx-xl-ngrid {
    margin-right: -1.875rem !important; }
  .mb-xl-ngrid,
  .my-xl-ngrid {
    margin-bottom: -1.875rem !important; }
  .ml-xl-ngrid,
  .mx-xl-ngrid {
    margin-left: -1.875rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1100px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFFFF5 !important; }

.text-primary {
  color: #306D50 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #193829 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #306D50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #193829 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #FFCB56 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffb30a !important; }

.text-danger {
  color: #DC5F17 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #974110 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-black {
  color: #272E43 !important; }

a.text-black:hover, a.text-black:focus {
  color: #0b0d13 !important; }

.text-white {
  color: #FFFFF5 !important; }

a.text-white:hover, a.text-white:focus {
  color: #ffffa9 !important; }

.text-beige {
  color: #F5F4EA !important; }

a.text-beige:hover, a.text-beige:focus {
  color: #dcd9b6 !important; }

.text-yellow {
  color: #FFCB56 !important; }

a.text-yellow:hover, a.text-yellow:focus {
  color: #ffb30a !important; }

.text-orange {
  color: #FF972D !important; }

a.text-orange:hover, a.text-orange:focus {
  color: #e07100 !important; }

.text-light-orange {
  color: #FFB37C !important; }

a.text-light-orange:hover, a.text-light-orange:focus {
  color: #ff8730 !important; }

.text-red {
  color: #DC5F17 !important; }

a.text-red:hover, a.text-red:focus {
  color: #974110 !important; }

.text-green {
  color: #306D50 !important; }

a.text-green:hover, a.text-green:focus {
  color: #193829 !important; }

.text-dark-blue {
  color: #1B3F47 !important; }

a.text-dark-blue:hover, a.text-dark-blue:focus {
  color: #060e10 !important; }

.text-blue {
  color: #B2E0E8 !important; }

a.text-blue:hover, a.text-blue:focus {
  color: #77c8d6 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(39, 46, 67, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 245, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFFFF5;
  background-color: #306D50; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFFFF5;
    background-color: #204a36; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 109, 80, 0.5); }

.badge-secondary {
  color: #FFFFF5;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFFFF5;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #FFFFF5;
  background-color: #306D50; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFFFF5;
    background-color: #204a36; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 109, 80, 0.5); }

.badge-info {
  color: #FFFFF5;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFFFF5;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FFCB56; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #ffbb23; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 203, 86, 0.5); }

.badge-danger {
  color: #FFFFF5;
  background-color: #DC5F17; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFFFF5;
    background-color: #ae4b12; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 95, 23, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #FFFFF5;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFFFF5;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-black {
  color: #FFFFF5;
  background-color: #272E43; }
  a.badge-black:hover, a.badge-black:focus {
    color: #FFFFF5;
    background-color: #141823; }
  a.badge-black:focus, a.badge-black.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 46, 67, 0.5); }

.badge-white {
  color: #212529;
  background-color: #FFFFF5; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #ffffc2; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 245, 0.5); }

.badge-beige {
  color: #212529;
  background-color: #F5F4EA; }
  a.badge-beige:hover, a.badge-beige:focus {
    color: #212529;
    background-color: #e5e2c7; }
  a.badge-beige:focus, a.badge-beige.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 244, 234, 0.5); }

.badge-yellow {
  color: #212529;
  background-color: #FFCB56; }
  a.badge-yellow:hover, a.badge-yellow:focus {
    color: #212529;
    background-color: #ffbb23; }
  a.badge-yellow:focus, a.badge-yellow.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 203, 86, 0.5); }

.badge-orange {
  color: #212529;
  background-color: #FF972D; }
  a.badge-orange:hover, a.badge-orange:focus {
    color: #212529;
    background-color: #f97e00; }
  a.badge-orange:focus, a.badge-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 151, 45, 0.5); }

.badge-light-orange {
  color: #212529;
  background-color: #FFB37C; }
  a.badge-light-orange:hover, a.badge-light-orange:focus {
    color: #212529;
    background-color: #ff9549; }
  a.badge-light-orange:focus, a.badge-light-orange.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 179, 124, 0.5); }

.badge-red {
  color: #FFFFF5;
  background-color: #DC5F17; }
  a.badge-red:hover, a.badge-red:focus {
    color: #FFFFF5;
    background-color: #ae4b12; }
  a.badge-red:focus, a.badge-red.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 95, 23, 0.5); }

.badge-green {
  color: #FFFFF5;
  background-color: #306D50; }
  a.badge-green:hover, a.badge-green:focus {
    color: #FFFFF5;
    background-color: #204a36; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 109, 80, 0.5); }

.badge-dark-blue {
  color: #FFFFF5;
  background-color: #1B3F47; }
  a.badge-dark-blue:hover, a.badge-dark-blue:focus {
    color: #FFFFF5;
    background-color: #0d1e22; }
  a.badge-dark-blue:focus, a.badge-dark-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(27, 63, 71, 0.5); }

.badge-blue {
  color: #212529;
  background-color: #B2E0E8; }
  a.badge-blue:hover, a.badge-blue:focus {
    color: #212529;
    background-color: #8bd0dc; }
  a.badge-blue:focus, a.badge-blue.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(178, 224, 232, 0.5); }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(39, 46, 67, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(39, 46, 67, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5d6c7; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #93b39f; }

.table-hover .table-primary:hover {
  background-color: #b6cbb9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b6cbb9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8d3; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7b7; }

.table-hover .table-secondary:hover {
  background-color: #c9ccc5; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c9ccc5; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c5d6c7; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #93b39f; }

.table-hover .table-success:hover {
  background-color: #b6cbb9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b6cbb9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5e4; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfd5; }

.table-hover .table-info:hover {
  background-color: #acdedc; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #acdedc; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff0c8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe4a2; }

.table-hover .table-warning:hover {
  background-color: #ffe9af; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe9af; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5d2b7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #edac82; }

.table-hover .table-danger:hover {
  background-color: #f2c4a1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2c4a1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdf6; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcf8; }

.table-hover .table-light:hover {
  background-color: #f8f8e1; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #f8f8e1; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8c2; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #959997; }

.table-hover .table-dark:hover {
  background-color: #b9bcb5; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bcb5; }

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c3c4c3; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #8f9298; }

.table-hover .table-black:hover {
  background-color: #b6b7b6; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #b6b7b6; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: #fffff5; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: #fffff5; }

.table-hover .table-white:hover {
  background-color: #ffffdc; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #ffffdc; }

.table-beige,
.table-beige > th,
.table-beige > td {
  background-color: #fcfcf2; }

.table-beige th,
.table-beige td,
.table-beige thead th,
.table-beige tbody + tbody {
  border-color: #faf9ef; }

.table-hover .table-beige:hover {
  background-color: #f7f7dd; }
  .table-hover .table-beige:hover > td,
  .table-hover .table-beige:hover > th {
    background-color: #f7f7dd; }

.table-yellow,
.table-yellow > th,
.table-yellow > td {
  background-color: #fff0c8; }

.table-yellow th,
.table-yellow td,
.table-yellow thead th,
.table-yellow tbody + tbody {
  border-color: #ffe4a2; }

.table-hover .table-yellow:hover {
  background-color: #ffe9af; }
  .table-hover .table-yellow:hover > td,
  .table-hover .table-yellow:hover > th {
    background-color: #ffe9af; }

.table-orange,
.table-orange > th,
.table-orange > td {
  background-color: #ffe2bd; }

.table-orange th,
.table-orange td,
.table-orange thead th,
.table-orange tbody + tbody {
  border-color: #ffc98d; }

.table-hover .table-orange:hover {
  background-color: #ffd7a4; }
  .table-hover .table-orange:hover > td,
  .table-hover .table-orange:hover > th {
    background-color: #ffd7a4; }

.table-light-orange,
.table-light-orange > th,
.table-light-orange > td {
  background-color: #ffead3; }

.table-light-orange th,
.table-light-orange td,
.table-light-orange thead th,
.table-light-orange tbody + tbody {
  border-color: #ffd7b6; }

.table-hover .table-light-orange:hover {
  background-color: #ffdeba; }
  .table-hover .table-light-orange:hover > td,
  .table-hover .table-light-orange:hover > th {
    background-color: #ffdeba; }

.table-red,
.table-red > th,
.table-red > td {
  background-color: #f5d2b7; }

.table-red th,
.table-red td,
.table-red thead th,
.table-red tbody + tbody {
  border-color: #edac82; }

.table-hover .table-red:hover {
  background-color: #f2c4a1; }
  .table-hover .table-red:hover > td,
  .table-hover .table-red:hover > th {
    background-color: #f2c4a1; }

.table-green,
.table-green > th,
.table-green > td {
  background-color: #c5d6c7; }

.table-green th,
.table-green td,
.table-green thead th,
.table-green tbody + tbody {
  border-color: #93b39f; }

.table-hover .table-green:hover {
  background-color: #b6cbb9; }
  .table-hover .table-green:hover > td,
  .table-hover .table-green:hover > th {
    background-color: #b6cbb9; }

.table-dark-blue,
.table-dark-blue > th,
.table-dark-blue > td {
  background-color: #bfc9c4; }

.table-dark-blue th,
.table-dark-blue td,
.table-dark-blue thead th,
.table-dark-blue tbody + tbody {
  border-color: #889b9b; }

.table-hover .table-dark-blue:hover {
  background-color: #b1bdb7; }
  .table-hover .table-dark-blue:hover > td,
  .table-hover .table-dark-blue:hover > th {
    background-color: #b1bdb7; }

.table-blue,
.table-blue > th,
.table-blue > td {
  background-color: #e9f6f1; }

.table-blue th,
.table-blue td,
.table-blue thead th,
.table-blue tbody + tbody {
  border-color: #d7efee; }

.table-hover .table-blue:hover {
  background-color: #d7efe5; }
  .table-hover .table-blue:hover > td,
  .table-hover .table-blue:hover > th {
    background-color: #d7efe5; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(39, 46, 67, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(30, 35, 51, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(30, 35, 51, 0.075); }

.table .thead-dark th {
  color: #FFFFF5;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #FFFFF5;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 245, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFFFF5;
    background-color: rgba(255, 255, 245, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1099.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

/*
*	Styles de l'outil d'administration
// scss-lint:disable ImportantRule, QualifyingElement, IdSelector, SelectorFormat
*/
body.login #login .message {
  margin-bottom: 20px; }

body.login #login #loginform  {
  margin-top: 0; }

body.login h1,
body.login #nav,
body.login .wpml-login-ls,
body.login #wpml-login-ls-form {
  display: none; }

body.login #backtoblog {
  margin-bottom: 0;
  text-align: center; }

body.login:not(.interim-login) {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  body.login:not(.interim-login) #login {
    padding-top: 0; }

body.login .language-switcher {
  display: none; }

.wp-admin {
  /* Admin lists */
  /* Badge */
  /* Editor formats menu */
  /* Improve disabled */
  /* Improve page attributes */
  /* Improve post meta box */
  /* Cleanup */
  /* Other improvements and fixes */
  /* WPML */
  /* Media library */
  /* Relevanssi */
  /* Taxonomies */
  /* Filters */
  /* Simplified checklists */
  /* WooCommerce */ }
  @media screen and (min-height: 500px) {
    .wp-admin #wp-auth-check-wrap #wp-auth-check {
      top: 50%;
      transform: translateY(-50%);
      min-height: 400px; } }
  .wp-admin td.page-title {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    -webkit-font-smoothing: antialiased; }
  .wp-admin .badge {
    font-size: 12px;
    font-weight: 400; }
  .wp-admin .mce-menu-item >  .mce-text {
    font-size: 14px !important;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
    text-transform: none !important;
    text-decoration: none !important;
    font-weight: 400 !important;
    font-style: normal !important; }
  .wp-admin .mce-menu-item.mce-menu-item-preview.mce-active > .mce-text {
    color: #FFFFF5; }
  .wp-admin .disabled {
    pointer-events: none; }
    .wp-admin .disabled > * {
      opacity: .5; }
  .wp-admin .postbox#pageparentdiv > .inside {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    padding: 1px 12px 7px; }
    .wp-admin .postbox#pageparentdiv > .inside .post-attributes-label-wrapper {
      width: 70px;
      position: relative;
      top: -3px; }
    .wp-admin .postbox#pageparentdiv > .inside select {
      width: calc(100% - 80px);
      max-width: calc(100% - 80px);
      height: 30px; }
    .wp-admin .postbox#pageparentdiv > .inside input {
      height: 30px; }
  .wp-admin .postbox {
    border-radius: 4px; }
    .wp-admin .postbox .post-attributes-help-text,
    .wp-admin .postbox .handle-actions .acf-hndle-cog,
    .wp-admin .postbox .handle-actions .handle-order-higher,
    .wp-admin .postbox .handle-actions .handle-order-lower {
      display: none !important; }
    .wp-admin .postbox .inside.acf-fields.-left .acf-label > label {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      cursor: default !important;
      color: #111;
      position: relative;
      top: 4px;
      font-size: 13px;
      font-weight: 500 !important; }
    .wp-admin .postbox .inside.acf-fields.-left ul.acf-radio-list:focus-within,
    .wp-admin .postbox .inside.acf-fields.-left ul.acf-checkbox-list:focus-within {
      border-color: transparent;
      border-radius: 0; }
  .wp-admin #side-sortables .acf-field.acf-field-true-false.no-label {
    padding: 12px !important; }
  .wp-admin #wpfooter,
  .wp-admin #wp-admin-bar-wp-logo,
  .wp-admin #wp-admin-bar-new-content,
  .wp-admin #wp-admin-bar-archive,
  .wp-admin #wp-admin-bar-wpo_purge_cache,
  .wp-admin #wp-admin-bar-my-account .avatar,
  .wp-admin #wp-admin-bar-ate-status-bar,
  .wp-admin .hide-if-no-customize,
  .wp-admin #local-storage-notice,
  .wp-admin #icl_translate_options ~ *,
  .wp-admin .otgs-installer-notice-wpml.otgs-installer-notice-connection-issues,
  .wp-admin .otgs-ico-help + br,
  .wp-admin .otgs-ico-help + br + br,
  .wp-admin #wp-admin-bar-otgs-wpml-development,
  .wp-admin .toplevel_page_wpseo_dashboard .update-plugins,
  .wp-admin .acf-repeater.-table.-empty .acf-table,
  .wp-admin #dashboard-widgets .postbox-container .meta-box-sortables::after,
  .wp-admin #wp-admin-bar-wpseo-menu,
  .wp-admin #contextual-help-link-wrap,
  .wp-admin .acfml-synchronise-repeatser-checkbox,
  .wp-admin .acfml-synchronise-repeater-checkbox,
  .wp-admin #acfml-field-group-synchronise,
  .wp-admin .acf-admin-notice.acf-escaped-html-notice,
  .wp-admin .mce-widget.mce-notification.mce-notification-info.mce-has-close.mce-in,
  .wp-admin .woocommerce-site-status-badge-live {
    display: none !important; }
  .wp-admin.wp-env-development .yoast-notification.notice.notice-warning,
  .wp-admin.wp-env-development .wpmltm-notice {
    display: none !important; }
  .wp-admin .acf-field select {
    padding: 2px 8px !important; }
  .wp-admin #wp-admin-bar-site-name .ab-item {
    padding-left: 8px; }
  .wp-admin .widefat th,
  .wp-admin .widefat td {
    padding: 10px 8px !important; }
    .wp-admin .widefat th input[type='checkbox'],
    .wp-admin .widefat td input[type='checkbox'] {
      margin-top: 1px; }
  .wp-admin th.sortable a,
  .wp-admin th.sorted a {
    padding: 6px 0 !important; }
  .wp-admin .smaller-editor > .acf-input {
    margin-top: -2rem; }
  .wp-admin .acf-fields.-left > .acf-field {
    padding-top: 12px;
    padding-bottom: 12px; }
  .wp-admin .acf-fields.-left .acf-field.no-group > .acf-label {
    padding-top: 12px;
    padding-bottom: 12px; }
  .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields {
    padding: 0;
    border: 0; }
    .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  {
      padding: 12px 0 !important; }
      .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  ~ .acf-field {
        padding-left: 10px !important;
        border-left: 0 !important; }
      .wp-admin .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field  ~ .acf-field-google-map {
        padding-left: 0 !important; }
  .wp-admin .acf-field[data-type='tab'] + .smaller-editor > .acf-input {
    margin-top: 0; }
  .wp-admin .acf-radio-list.acf-hl {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .wp-admin .acf-radio-list.acf-hl input[type='radio'] {
      margin-top: -3px; }
  .wp-admin #translation_of_wrap,
  .wp-admin #icl_document_language_dropdown,
  .wp-admin #icl_translation_priority_dropdown,
  .wp-admin .attachment-translation_priority-filter,
  .wp-admin .compat-field-translation_priority,
  .wp-admin .otgs-toggle-group,
  .wp-admin #icl_cfo,
  .wp-admin .otgs-ico-help,
  .wp-admin #icl_minor_change_box,
  .wp-admin #translation-editor-post-settings {
    display: none !important; }
  .wp-admin #icl_set_duplicate {
    display: block !important;
    margin: 10px 0 0 0 !important;
    max-width: 100%; }
  .wp-admin.post-new-php #icl_div {
    display: none !important; }
  .wp-admin #delete-action {
    margin-top: -8px; }
  .wp-admin #icl_translations_table,
  .wp-admin .icl_box_paragraph,
  .wp-admin #icl_translate_options {
    margin-bottom: 0 !important; }
  .wp-admin .icl_box_paragraph > p:first-child {
    margin-bottom: 8px; }
  .wp-admin #translation-editor-post-settings,
  .wp-admin .inside >  br[clear='all'] {
    display: none; }
  .wp-admin .media-sidebar {
    max-width: 50%;
    width: 332px; }
    .wp-admin .media-sidebar .eml-toggle-collapse {
      display: none; }
  .wp-admin .attachments-wrapper {
    right: 365px !important;
    transition: all .25s ease-in-out !important; }
    .wp-admin .attachments-wrapper[style*='right: 0px;'] {
      right: 0 !important; }
  .wp-admin .media-modal.acf-expanded .media-sidebar {
    max-width: 100%; }
  .wp-admin .attachment-details > h2:first-of-type {
    margin-top: 12px !important; }
  .wp-admin .attachment-details #alt-text-description,
  .wp-admin .attachment-details .copy-to-clipboard-container {
    display: none; }
  .wp-admin .attachment-details span.setting {
    margin-top: 0 !important;
    margin-bottom: 4px !important; }
  .wp-admin .attachment-details .attachment-info {
    display: flex !important;
    flex-direction: column; }
  .wp-admin .eml-collapse {
    width: 100% !important;
    height: auto !important;
    display: flex !important;
    flex-direction: column; }
    .wp-admin .eml-collapse span.setting[data-setting='title'] {
      order: -1; }
  .wp-admin .attachments-browser .attachments {
    right: 364px; }
  .wp-admin .attachment-details {
    display: flex !important;
    flex-direction: column; }
    .wp-admin .attachment-details span.setting[data-setting='title'] {
      order: 0; }
    .wp-admin .attachment-details span.setting[data-setting='alt'] {
      order: 1; }
    .wp-admin .attachment-details span.setting[data-setting='alt'] {
      order: 1; }
    .wp-admin .attachment-details span.setting[data-setting='caption'] {
      order: 2; }
    .wp-admin .attachment-details span.setting[data-setting='description'] {
      order: 3; }
  .wp-admin .media-frame-content .attachment-details {
    display: block !important; }
    .wp-admin .media-frame-content .attachment-details .compat-field-media_category .eml-tax-label label {
      width: 127px; }
    .wp-admin .media-frame-content .attachment-details .compat-field-media_category .term-list {
      margin-left: 147px; }
  .wp-admin .acf-gallery-side .term-list {
    margin-left: 0 !important; }
  .wp-admin .compat-attachment-fields .acf-field {
    margin-top: 0 !important;
    margin-bottom: 4px !important; }
  .wp-admin .compat-field-wpo_compress_image {
    padding-top: 32px; }
  .wp-admin .compat-field-media_category .label label {
    display: block;
    text-align: right;
    width: 100px;
    margin-right: 14px; }
    .wp-admin .compat-field-media_category .label label span {
      display: block !important;
      text-align: right !important; }
    .wp-admin .compat-field-media_category .label label br.clear {
      display: none; }
  .wp-admin .compat-field-media_category .term-list {
    background: transparent;
    border: 0;
    padding: 0;
    margin-left: 116px;
    margin-top: 5px; }
    .wp-admin .compat-field-media_category .term-list li, .wp-admin .compat-field-media_category .term-list ul {
      margin-top: 0;
      margin-bottom: 0; }
  .wp-admin .media-types-required-info,
  .wp-admin .acf-expand-details {
    display: none; }
  .wp-admin .acf-gallery-toolbar .acf-hl .acf-fr {
    max-width: calc(100% - 142px); }
  .wp-admin .attachments-browser .compat-item {
    padding-bottom: 1px; }
  .wp-admin .attachments-browser .attachment-display-settings > h2,
  .wp-admin .attachments-browser .attachment-display-settings .setting {
    display: none; }
  .wp-admin .attachments-browser .attachment-display-settings .setting:last-child {
    display: block; }
    .wp-admin .attachments-browser .attachment-display-settings .setting:last-child #attachment-display-settings-size {
      position: relative;
      left: 2px; }
  .wp-admin .attachments-browser .media-toolbar {
    right: 372px; }
    .wp-admin .attachments-browser .media-toolbar .media-toolbar-secondary {
      width: 100%; }
    .wp-admin .attachments-browser .media-toolbar #media-attachment-filters {
      max-width: 30%; }
    .wp-admin .attachments-browser .media-toolbar #media-attachment-media_category-filters {
      max-width: 33.33%; }
    .wp-admin .attachments-browser .media-toolbar #media-search-input {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0; }
    .wp-admin .attachments-browser .media-toolbar #reset-all-filters {
      display: none; }
  .wp-admin.mlmi-auto-size label[for='attachment-display-settings-size'],
  .wp-admin.mlmi-auto-size select#attachment-display-settings-size {
    display: none !important; }
  .wp-admin.mlmi-media-disabled #wp-media-grid > h1 + a,
  .wp-admin.mlmi-media-disabled .attachment-actions,
  .wp-admin.mlmi-media-disabled.post-type-attachment #edit-slug-buttons,
  .wp-admin.mlmi-media-disabled .wp_attachment_image input[id*='imgedit-open-btn-'],
  .wp-admin.mlmi-media-disabled #menu-item-upload,
  .wp-admin.mlmi-media-disabled a.edit-attachment {
    display: none !important; }
  .wp-admin #relevanssi_hidebox p + p {
    margin-top: 0;
    margin-bottom: 4px; }
    .wp-admin #relevanssi_hidebox p + p + p {
      display: none; }
  .wp-admin #relevanssi_hidebox textarea + p {
    margin-top: 2px; }
  .wp-admin #relevanssi_hidebox input[type='checkbox'] {
    margin-right: 2px;
    position: relative;
    top: -1px; }
  .wp-admin form#addtag {
    margin-top: 22px; }
    .wp-admin form#addtag .form-field {
      margin: 6px 0 0 !important; }
      .wp-admin form#addtag .form-field > p {
        display: none; }
    .wp-admin form#addtag .mlmi-builder-section,
    .wp-admin form#addtag #dashboard-widgets {
      display: none; }
  .wp-admin form[name='edittag'] {
    margin-top: 20px; }
    .wp-admin form[name='edittag'] .form-field th,
    .wp-admin form[name='edittag'] .form-field td {
      padding-top: 0; }
      .wp-admin form[name='edittag'] .form-field th p.description,
      .wp-admin form[name='edittag'] .form-field td p.description {
        display: none; }
    .wp-admin form[name='edittag'] .form-field.term-description-wrap .mce-edit-area > iframe {
      height: 180px !important; }
    .wp-admin form[name='edittag'] .form-field .term-permalink {
      font-size: 13px !important;
      padding-left: 1px; }
      .wp-admin form[name='edittag'] .form-field .term-permalink > strong {
        color: #646970 !important;
        padding-right: 2px; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox-container {
      width: 480px !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] h3.hndle {
      padding: 8px 12px !important;
      font-weight: bold !important;
      margin: 0 !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] .inside  {
      padding-top: 0 !important;
      margin-top: 0 !important; }
    .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options {
      margin-top: 12px;
      margin-bottom: 0; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options > p {
        margin-bottom: 0 !important; }
        .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options > p > b {
          color: #1d2327; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table + br {
        display: none; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr > td {
        padding-left: 0;
        padding-right: 0;
        width: auto !important; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr:first-child > td {
        padding-top: 6px; }
      .wp-admin form[name='edittag'] .wpml-term-languages-wrap .postbox[id^='icl_tax'] #icl_translate_options table#icl_translations_table > tbody > tr:last-child > td {
        padding-bottom: 3px; }
    .wp-admin form[name='edittag'] .form-table {
      width: calc(100vw - 250px);
      max-width: none; }
  .wp-admin #posts-filter #post-query-submit {
    margin-left: 0 !important;
    margin-top: 0 !important; }
  .wp-admin #posts-filter input[name='export_excel'] {
    margin-right: 6px; }
  .wp-admin ul.category-tabs {
    display: none; }
  .wp-admin .tabs-panel {
    max-height: none !important;
    margin: 0 !important;
    padding: 0 0 0 1px !important;
    border: 0 !important; }
    .wp-admin .tabs-panel .categorychecklist {
      margin: 2px 0 0 !important; }
  .wp-admin .woocommerce-help-tip,
  .wp-admin #set-post-thumbnail-desc,
  .wp-admin #quick_edit_notice,
  .wp-admin .wcml-pointer-products_translation {
    display: none !important; }

/*
* Core dashboard
*/
.wp-admin.settings_page_acf-options-core .acf-fields.-left .acf-field.no-group .acf-input .acf-fields > .acf-field {
  padding: 8px 0 !important; }

.wp-admin.settings_page_acf-options-core .acf-field[data-name^='mlmi_core_packages_'] .acf-fields.-border {
  border: 0 !important; }

.wp-admin.settings_page_acf-options-core .type-definition {
  border: 0 !important;
  padding: 0 !important; }

.wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields {
  display: flex;
  flex-flow: row wrap;
  padding: 0 8px 9px 0 !important; }
  .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field {
    flex: 0 0 33.33%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field[data-width="50"] {
      flex: 0 0 50%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field[data-width="100"] {
      flex: 0 0 100%; }
    .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field.flex-100 {
      flex: 0 0 100%;
      height: 0;
      margin: 0;
      padding: 0 !important; }
      .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field.flex-100 * {
        display: none; }
  .wp-admin.settings_page_acf-options-core .acf-field.type-definition .acf-repeater .acf-row .acf-fields > .acf-field {
    padding: 9px 0 0 8px !important;
    border: 0; }

.wp-admin .datepicker-filter {
  border-radius: 3px;
  width: 115px;
  text-align: center;
  margin: -1px 4px 0 0; }

.wp-admin .table td {
  text-align: center !important; }

.wp-admin .table thead th,
.wp-admin .table tfoot th,
.wp-admin .table tfoot td {
  background: #eee !important; }

.message-as-title + .smaller-editor {
  margin-top: 0.625rem; }

.taxonomy-blog-author .term-parent-wrap,
.taxonomy-blog-author .term-description-wrap {
  display: none; }

.taxonomy-blog-author #acf-form-data + h2 {
  display: none; }
  .taxonomy-blog-author #acf-form-data + h2 + .form-table {
    margin-top: -.5em; }

.admin-disable-edit #titlewrap {
  position: relative; }

.admin-disable-edit #titlewrap,
.admin-disable-edit .postbox:not(#acf-address_info):not(#submitdiv):not(#relevanssi_hidebox) .inside,
.admin-disable-edit .inside.acf-fields,
.admin-disable-edit .acf-gallery-main,
.admin-disable-edit .select2 {
  pointer-events: none; }
  .admin-disable-edit #titlewrap::after,
  .admin-disable-edit .postbox:not(#acf-address_info):not(#submitdiv):not(#relevanssi_hidebox) .inside::after,
  .admin-disable-edit .inside.acf-fields::after,
  .admin-disable-edit .acf-gallery-main::after,
  .admin-disable-edit .select2::after {
    display: block;
    position: absolute;
    content: '';
    background: rgba(240, 240, 241, 0.5);
    inset: 0; }

.admin-disable-edit #acf-group_6573267b198e6 .inside.acf-fields {
  pointer-events: all !important; }
  .admin-disable-edit #acf-group_6573267b198e6 .inside.acf-fields::after {
    display: none !important; }

.admin-disable-edit #edit-slug-buttons {
  display: none; }

.admin-disable-edit #relevanssi_hidebox p {
  display: block !important; }

.admin-disable-edit #relevanssi_hidebox .inside #relevanssi_metabox + p + p + p,
.admin-disable-edit #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p,
.admin-disable-edit #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p + p + p + p,
.admin-disable-edit #relevanssi_hidebox .inside p:last-of-type {
  display: none !important; }

.admin-disable-edit #relevanssi_hidebox .inside textarea[name='relevanssi_unpin'] + p {
  margin-bottom: 0 !important; }

.admin-disable-edit #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p + p + p {
  margin-top: 10px !important; }

.admin-disable-edit .postbox .inside:not(.acf-fields)::after {
  top: -6px !important; }

.admin-disable-edit #acf-address_info .inside {
  pointer-events: all; }
  .admin-disable-edit #acf-address_info .inside::after  {
    display: none !important; }

#side-sortables .acf-field-date-picker {
  padding-top: 8px !important;
  padding-bottom: 10px !important; }
  #side-sortables .acf-field-date-picker .acf-label {
    margin-bottom: 6px !important; }
