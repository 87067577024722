//------------------------------------------------------------------
//	BOOTSTRAP SETUP
//------------------------------------------------------------------
@import 'common/variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/tables';

//------------------------------------------------------------------
//	CORE ADMIN STYLES
//------------------------------------------------------------------
@import 'node_modules/mlmi-library/components/admin/v1/admin';

.wp-admin {
  .datepicker-filter {
    border-radius: 3px;
    width: 115px;
    text-align: center;
    margin: -1px 4px 0 0;
  }

  .table {
    td {
      text-align: center !important;
    }

    thead th,
    tfoot th,
    tfoot td {
      background: #eee !important;
    }
  }
}

.message-as-title + .smaller-editor {
  margin-top: rem(10);
}

.taxonomy-blog-author {
  .term-parent-wrap,
  .term-description-wrap {
    display: none;
  }

  #acf-form-data + h2 {
    display: none;

    & + .form-table {
      margin-top: -.5em;
    }
  }
}

.admin-disable-edit {
  #titlewrap {
    position: relative;
  }

  #titlewrap,
  .postbox:not(#acf-address_info):not(#submitdiv):not(#relevanssi_hidebox) .inside,
  .inside.acf-fields,
  .acf-gallery-main,
  .select2 {
    pointer-events: none;

    &::after {
      display: block;
      position: absolute;
      content: '';
      background: rgba(#f0f0f1, .5);
      inset: 0;
    }
  }

  #acf-group_6573267b198e6 .inside.acf-fields {
    pointer-events: all !important;

    &::after {
      display: none !important;
    }
  }

  #edit-slug-buttons {
    display: none;
  }

  #relevanssi_hidebox p {
    display: block !important;
  }

  #relevanssi_hidebox .inside #relevanssi_metabox + p + p + p,
  #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p,
  #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p + p + p + p,
  #relevanssi_hidebox .inside p:last-of-type {
    display: none !important;
  }

  #relevanssi_hidebox .inside textarea[name='relevanssi_unpin'] + p {
    margin-bottom: 0 !important;
  }

  #relevanssi_hidebox .inside textarea[name='relevanssi_pin'] + p + p + p {
    margin-top: 10px !important;
  }

  .postbox .inside:not(.acf-fields)::after {
    top: -6px !important;
  }

  #acf-address_info .inside {
    pointer-events: all;

    &::after {
      display: none !important;
    }
  }
}

#side-sortables {
  .acf-field-date-picker {
    padding-top: 8px !important;
    padding-bottom: 10px !important;

    .acf-label {
      margin-bottom: 6px !important;
    }
  }
}
